import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home/home";
import ProcodePrivacyPolicy from "./pages/policy/prcode/prcode.policy";
import XoSoPrivacyPolicy from "./pages/policy/xoso/xoso.policy";
import SupportPage from "./pages/support/support";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="support" element={<SupportPage />} />
        <Route path="prcode-privacy-policy" element={<ProcodePrivacyPolicy />} />
        <Route path="xoso-privacy-policy" element={<XoSoPrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
