import React from "react";
import "./xoso.policy.css";

const XoSoPrivacyPolicy = () => {
  return (
    <div className="xoso-policy">
      <h1>Điều khoản và Chính sách Ứng dụng Xổ Số</h1>

      <section>
        <h2>1. Giới thiệu</h2>
        <p>
          Chào mừng bạn đến với Ứng dụng Xổ Số ("Ứng dụng"). Bằng việc sử dụng Ứng dụng này, bạn đồng ý tuân thủ các
          điều khoản và điều kiện được nêu trong tài liệu này ("Điều khoản"). Vui lòng đọc kỹ các Điều khoản này trước
          khi sử dụng Ứng dụng.
        </p>
      </section>

      <section>
        <h2>2. Định nghĩa</h2>
        <ul>
          <li>
            "Chúng tôi", "của chúng tôi", hoặc "Công ty" đề cập đến [Tên Công ty], chủ sở hữu và vận hành Ứng dụng.
          </li>
          <li>"Bạn" hoặc "Người dùng" đề cập đến bất kỳ cá nhân hoặc tổ chức nào sử dụng Ứng dụng.</li>
          <li>
            "Dữ liệu" đề cập đến bất kỳ thông tin nào được tạo, thu thập hoặc xử lý thông qua việc sử dụng Ứng dụng.
          </li>
        </ul>
      </section>

      <section>
        <h2>3. Mô tả Dịch vụ</h2>
        <p>
          Ứng dụng Xổ Số của chúng tôi cho phép người theo dõi, thống kê, xem kết quả, bình luận kết quả Xổ Số trên lãnh
          thổ Việt Nam. Các tính năng bao gồm:
        </p>
        <ul>
          <li>Xem trực tiếp kết quả</li>
          <li>Thống kê kết quả</li>
          <li>Thảo luận kết quả</li>
          <li>Các công cụ phân tích kết quả</li>
        </ul>
      </section>

      <section>
        <h2>4. Đăng ký và Tài khoản</h2>
        <p>
          4.1. Để sử dụng một số tính năng của Ứng dụng, bạn có thể cần tạo một tài khoản. Bạn đồng ý cung cấp thông tin
          chính xác, đầy đủ và cập nhật khi đăng ký.
        </p>
        <p>
          4.2. Bạn chịu trách nhiệm duy trì bảo mật tài khoản và mật khẩu của mình. Bạn đồng ý thông báo cho chúng tôi
          ngay lập tức về bất kỳ việc sử dụng trái phép nào đối với tài khoản của bạn.
        </p>
      </section>

      <section>
        <h2>5. Quyền riêng tư và Bảo vệ Dữ liệu</h2>
        <p>
          5.1. Chúng tôi coi trọng quyền riêng tư của bạn. Việc thu thập và sử dụng thông tin cá nhân của bạn tuân theo
          Chính sách Quyền riêng tư của chúng tôi, có thể tìm thấy tại [liên kết đến Chính sách Quyền riêng tư].
        </p>
        <p>
          5.2. Bằng việc sử dụng Ứng dụng, bạn đồng ý với việc thu thập, sử dụng và xử lý dữ liệu của bạn như được mô tả
          trong Chính sách Quyền riêng tư của chúng tôi.
        </p>
      </section>

      <section>
        <h2>6. Giấy phép và Hạn chế Sử dụng</h2>
        <p>
          6.1. Chúng tôi cấp cho bạn giấy phép có giới hạn, không độc quyền, không thể chuyển nhượng để sử dụng Ứng dụng
          cho mục đích cá nhân hoặc kinh doanh.
        </p>
        <p>6.2. Bạn đồng ý không:</p>
        <ul>
          <li>Sửa đổi, điều chỉnh hoặc tạo các tác phẩm phái sinh từ Ứng dụng</li>
          <li>Đảo ngược kỹ thuật, biên dịch ngược hoặc cố gắng trích xuất mã nguồn của Ứng dụng</li>
          <li>Sử dụng Ứng dụng cho bất kỳ mục đích bất hợp pháp nào</li>
          <li>Can thiệp hoặc phá vỡ tính toàn vẹn hoặc hiệu suất của Ứng dụng</li>
        </ul>
      </section>

      <section>
        <h2>7. Quyền Sở hữu Trí tuệ</h2>
        <p>
          7.1. Tất cả quyền sở hữu trí tuệ liên quan đến Ứng dụng và nội dung của nó thuộc về Công ty hoặc người cấp
          phép của chúng tôi.
        </p>
        <p>
          7.2. Bạn không được sao chép, phân phối, sửa đổi hoặc tạo các tác phẩm phái sinh từ bất kỳ phần nào của Ứng
          dụng mà không có sự đồng ý bằng văn bản của chúng tôi.
        </p>
      </section>

      <section>
        <h2>8. Nội dung Người dùng</h2>
        <p>
          8.1. Bạn giữ quyền sở hữu đối với bất kỳ nội dung nào bạn tạo hoặc tải lên thông qua Ứng dụng ("Nội dung Người
          dùng").
        </p>
        <p>
          8.2. Bằng việc tải lên Nội dung Người dùng, bạn cấp cho chúng tôi giấy phép toàn cầu, không độc quyền, miễn
          phí bản quyền để sử dụng, sao chép, sửa đổi và hiển thị Nội dung Người dùng nhằm mục đích cung cấp và cải
          thiện Ứng dụng.
        </p>
      </section>

      <section>
        <h2>9. Tuyên bố Từ chối Bảo đảm</h2>
        <p>
          9.1. Ứng dụng được cung cấp "nguyên trạng" và "như có sẵn", không có bất kỳ bảo đảm nào, dù rõ ràng hay ngụ ý.
        </p>
        <p>
          9.2. Chúng tôi không đảm bảo rằng Ứng dụng sẽ luôn có sẵn, không bị gián đoạn, kịp thời, an toàn hoặc không có
          lỗi.
        </p>
      </section>

      <section>
        <h2>10. Giới hạn Trách nhiệm</h2>
        <p>
          10.1. Trong phạm vi tối đa được pháp luật cho phép, Công ty sẽ không chịu trách nhiệm về bất kỳ thiệt hại trực
          tiếp, gián tiếp, ngẫu nhiên, đặc biệt hoặc hậu quả nào phát sinh từ việc sử dụng hoặc không thể sử dụng Ứng
          dụng.
        </p>
      </section>

      <section>
        <h2>11. Bồi thường</h2>
        <p>
          Bạn đồng ý bồi thường và giữ cho Công ty và các chi nhánh, nhân viên, đại lý và đối tác của chúng tôi không bị
          tổn hại từ bất kỳ khiếu nại, tổn thất, trách nhiệm pháp lý, chi phí và phí tổn nào phát sinh từ việc vi phạm
          các Điều khoản này của bạn hoặc việc sử dụng không đúng Ứng dụng.
        </p>
      </section>

      <section>
        <h2>12. Thay đổi đối với Ứng dụng và Điều khoản</h2>
        <p>
          12.1. Chúng tôi bảo lưu quyền sửa đổi, tạm ngừng hoặc ngừng Ứng dụng (hoặc bất kỳ phần nào của nó) vào bất kỳ
          lúc nào mà không cần thông báo trước.
        </p>
        <p>
          12.2. Chúng tôi có thể cập nhật các Điều khoản này theo thời gian. Chúng tôi sẽ thông báo cho bạn về bất kỳ
          thay đổi quan trọng nào bằng cách đăng thông báo trong Ứng dụng hoặc gửi email cho bạn.
        </p>
      </section>

      <section>
        <h2>13. Chấm dứt</h2>
        <p>
          13.1. Chúng tôi có thể chấm dứt hoặc tạm ngừng quyền truy cập của bạn vào Ứng dụng ngay lập tức, mà không cần
          thông báo trước, vì bất kỳ lý do gì, bao gồm cả việc vi phạm các Điều khoản này.
        </p>
        <p>13.2. Khi chấm dứt, quyền sử dụng Ứng dụng của bạn sẽ chấm dứt ngay lập tức.</p>
      </section>

      <section>
        <h2>14. Luật Áp dụng và Giải quyết Tranh chấp</h2>
        <p>14.1. Các Điều khoản này sẽ được điều chỉnh và giải thích theo luật pháp của [Quốc gia/Tiểu bang].</p>
        <p>
          14.2. Bất kỳ tranh chấp nào phát sinh từ hoặc liên quan đến các Điều khoản này hoặc Ứng dụng sẽ được giải
          quyết thông qua trọng tài ràng buộc theo quy tắc của [Tổ chức Trọng tài].
        </p>
      </section>

      <section>
        <h2>15. Chính sách pháp luật Việt Nam</h2>
        <p>
          15.1. Ứng dụng của chúng tôi chỉ thống kê kết quả xổ số Việt Nam. Chúng tôi tuyệt đối ko chia sẻ hoặc xúi dục
          người chơi tham gia Lô Đề, và các hành vi vi phạm pháp luật Việt Nam.
        </p>
        <p>15.2. Chúng tôi khuyến khích người chơi tuân thủ chính sách pháp luật Việt Nam và vui chơi lành mạnh.</p>
        <p>
          15.3. Nghiêm cấm người chơi chia sẻ hoặc xúi dục người khác tham gia vào lô đề cờ bạc, nói tục hoặc phỉ báng,
          nói xấu người khác trên thảo luận, nếu phát hiện hành vi vi phạm chúng tôi sẽ đóng vĩnh viễn tài khoản của
          bạn.
        </p>
      </section>

      <section>
        <h2>16. Liên hệ</h2>
        <p>
          Nếu bạn có bất kỳ câu hỏi nào về các Điều khoản này, vui lòng liên hệ với chúng tôi tại support@appslife.one.
        </p>
      </section>

      <p>
        Bằng việc sử dụng Ứng dụng Xổ Số của chúng tôi, bạn xác nhận rằng bạn đã đọc, hiểu và đồng ý tuân thủ các Điều
        khoản và Điều kiện này.
      </p>

      <p>Cập nhật lần cuối: 29 tháng 8 năm 2024</p>
    </div>
  );
};

export default XoSoPrivacyPolicy;
