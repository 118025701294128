import React from "react";
import "./prcode.policy.css";

const ProcodePrivacyPolicy = () => {
  return (
    <div className="prcode-policy">
      <h1>Terms and Policies for Prcode Application</h1>

      <section>
        <h2>1. Introduction</h2>
        <p>
          Welcome to our Prcode Application ("Application"). By using this Application, you agree to comply with the
          terms and conditions outlined in this document ("Terms"). Please read these Terms carefully before using the
          Application.
        </p>
      </section>

      <section>
        <h2>2. Definitions</h2>
        <ul>
          <li>"We", "our", or "Company" refers to [Company Name], the owner and operator of the Application.</li>
          <li>"You" or "User" refers to any individual or organization using the Application.</li>
          <li>"Data" refers to any information created, collected, or processed through the use of the Application.</li>
        </ul>
      </section>

      <section>
        <h2>3. Service Description</h2>
        <p>
          Our Prcode Application allows users to scan and decode various types of barcodes using their mobile device's
          camera. Features include:
        </p>
        <ul>
          <li>Scanning 1D and 2D barcodes</li>
          <li>Storing scan history</li>
          <li>Creating QR codes</li>
          <li>Sharing scan results</li>
        </ul>
      </section>

      <section>
        <h2>4. Registration and Account</h2>
        <p>
          4.1. To use certain features of the Application, you may need to create an account. You agree to provide
          accurate, complete, and up-to-date information when registering.
        </p>
        <p>
          4.2. You are responsible for maintaining the security of your account and password. You agree to notify us
          immediately of any unauthorized use of your account.
        </p>
      </section>

      <section>
        <h2>5. Privacy and Data Protection</h2>
        <p>
          5.1. We value your privacy. The collection and use of your personal information is subject to our Privacy
          Policy, which can be found at [link to Privacy Policy].
        </p>
        <p>
          5.2. By using the Application, you agree to the collection, use, and processing of your data as described in
          our Privacy Policy.
        </p>
      </section>

      <section>
        <h2>6. License and Use Restrictions</h2>
        <p>
          6.1. We grant you a limited, non-exclusive, non-transferable license to use the Application for personal or
          business purposes.
        </p>
        <p>6.2. You agree not to:</p>
        <ul>
          <li>Modify, adapt, or create derivative works from the Application</li>
          <li>Reverse engineer, decompile, or attempt to extract the source code of the Application</li>
          <li>Use the Application for any illegal purpose</li>
          <li>Interfere with or disrupt the integrity or performance of the Application</li>
        </ul>
      </section>

      <section>
        <h2>7. Intellectual Property Rights</h2>
        <p>
          7.1. All intellectual property rights related to the Application and its content belong to the Company or our
          licensors.
        </p>
        <p>
          7.2. You may not copy, distribute, modify, or create derivative works from any part of the Application without
          our written consent.
        </p>
      </section>

      <section>
        <h2>8. User Content</h2>
        <p>8.1. You retain ownership of any content you create or upload through the Application ("User Content").</p>
        <p>
          8.2. By uploading User Content, you grant us a worldwide, non-exclusive, royalty-free license to use, copy,
          modify, and display the User Content for the purpose of providing and improving the Application.
        </p>
      </section>

      <section>
        <h2>9. Disclaimer of Warranties</h2>
        <p>
          9.1. The Application is provided "as is" and "as available", without any warranties, whether express or
          implied.
        </p>
        <p>
          9.2. We do not guarantee that the Application will always be available, uninterrupted, timely, secure, or
          error-free.
        </p>
      </section>

      <section>
        <h2>10. Limitation of Liability</h2>
        <p>
          10.1. To the maximum extent permitted by law, the Company shall not be liable for any direct, indirect,
          incidental, special, or consequential damages arising from the use or inability to use the Application.
        </p>
      </section>

      <section>
        <h2>11. Indemnification</h2>
        <p>
          You agree to indemnify and hold the Company and our affiliates, employees, agents, and partners harmless from
          any claims, losses, liabilities, costs, and expenses arising from your violation of these Terms or improper
          use of the Application.
        </p>
      </section>

      <section>
        <h2>12. Changes to the Application and Terms</h2>
        <p>
          12.1. We reserve the right to modify, suspend, or discontinue the Application (or any part of it) at any time
          without prior notice.
        </p>
        <p>
          12.2. We may update these Terms from time to time. We will notify you of any significant changes by posting a
          notice in the Application or sending you an email.
        </p>
      </section>

      <section>
        <h2>13. Termination</h2>
        <p>
          13.1. We may terminate or suspend your access to the Application immediately, without prior notice, for any
          reason, including breach of these Terms.
        </p>
        <p>13.2. Upon termination, your right to use the Application will immediately cease.</p>
      </section>

      <section>
        <h2>14. Applicable Law and Dispute Resolution</h2>
        <p>14.1. These Terms will be governed and interpreted according to the laws of [Country/State].</p>
        <p>
          14.2. Any dispute arising from or related to these Terms or the Application will be resolved through binding
          arbitration under the rules of [Arbitration Organization].
        </p>
      </section>

      <section>
        <h2>15. Contact</h2>
        <p>If you have any questions about these Terms, please contact us at support@appslife.one.</p>
      </section>

      <p>
        By using our Prcode Application, you acknowledge that you have read, understood, and agree to abide by these
        Terms and Conditions.
      </p>

      <p>Last updated: 29 Aug 2024</p>
    </div>
  );
};

export default ProcodePrivacyPolicy;
